<template>
  <div>
    <div class="flex justify-space-between">
      <div class="box self-flex-end"><h2 class="mv-0">CREATE ITEM</h2></div>
      <div class="box"><el-button @click="$onCommandParams('warehouse')">BACK</el-button></div>
    </div>
    <el-divider class="mv-10 o-020"></el-divider>
      <vue-scroll class="mt-30" style="max-width: 600px; height: 600px;">
          <el-form label-position="left" ref="form" :rules="rules" label-width="160px" :model="form" size="small">
              <el-form-item label="Name" prop="name">
                  <el-input v-model="form.name"></el-input>
              </el-form-item>
              <el-form-item label="Description" prop="description">
                  <el-input v-model="form.description"></el-input>
              </el-form-item>
              <el-form-item label="Barcode" prop="barcode">
                  <el-input v-model="form.barcode"></el-input>
              </el-form-item>
              <el-form-item label="Company" prop="company">
                <el-select :popper-append-to-body="false" size="small" v-model="form.company">
                  <el-option
                    v-for="item in companiesForSelect"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value">
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="Category" prop="category">
                <el-select :popper-append-to-body="false" size="small" v-model="form.category">
                  <el-option
                    v-for="item in categoriesForSelect"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value">
                  </el-option>
                </el-select>
              </el-form-item>
              <template v-if="getCategoryNameById[form.category] === 'Skates'">
                <el-form-item label="Skates Brand" prop="brand">
                    <el-input v-model="form.skates.brand"></el-input>
                </el-form-item>
                <el-form-item label="Skates Type" prop="type">
                    <el-input v-model="form.skates.type"></el-input>
                </el-form-item>
                <el-form-item label="Skates Color" prop="color">
                    <el-input v-model="form.skates.color"></el-input>
                </el-form-item>
                <el-form-item label="Skates Size" prop="size">
                    <el-input v-model="form.skates.size"></el-input>
                </el-form-item>
              </template>
              <el-form-item label="Price" prop="cost">
                  <el-input-number :controls="false" v-model="form.cost"></el-input-number>
              </el-form-item>
              <el-form-item label="Limit" prop="limit">
                  <el-input-number :controls="false" v-model="form.lowerLimit"></el-input-number>
              </el-form-item>
              <el-form-item label="Is Rent" prop="isRent">
                <el-switch v-model="form.isRent"></el-switch>
              </el-form-item>
              <el-form-item>
                <el-button size="default" type="primary" @click="submit('form')">Submit</el-button>
              </el-form-item>
          </el-form>
      </vue-scroll>

  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  name: 'CreateItem',

  data () {
    return {
      rules: {
        name: [
          { required: true, message: 'Please input name', trigger: 'blur' }
        ],
        category: [
          { required: true, message: 'Please select category', trigger: 'blur' }
        ]
      },
      form: {
        skates: {
          brand: '',
          type: '',
          color: '',
          size: ''
        },
        name: '',
        description: '',
        category: '',
        company: '',
        lowerLimit: '',
        barcode: '',
        cost: '',
        isRent: false
      },
      buffer: {
        date: -1,
        text: ''
      }
    }
  },

  computed: {
    ...mapGetters([
      'companiesForSelect',
      'categoriesForSelect',
      'getCategoryNameById'
    ])
  },

  methods: {
    submit (formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.$store.dispatch('addItem', this.form)
            .then(() => {
              this.$message({
                showClose: true,
                message: 'New item created successfully',
                type: 'success'
              })
            })
            .then(() => {
              this.$onCommandParams('warehouse')
            })
        }
      })
    },
    keyDown: function (e) {
      const today = new Date()
      if (this.buffer.text === '') {
        this.buffer.date = today.getTime()
        this.buffer.text = this.buffer.text + '' + e.key
      } else if (e.key === 'ArrowDown' && this.buffer.text.slice(-5) === 'Enter') {
        this.scanBarCode(this.buffer.text.slice(0, -5))
        Object.assign(this.buffer, {
          date: -1,
          text: ''
        })
      } else if (this.buffer.date > today.getTime() - 1000) {
        this.buffer.text = this.buffer.text + '' + e.key
      } else {
        Object.assign(this.buffer, {
          date: today.getTime(),
          text: e.key
        })
      }
    },
    scanBarCode (text) {
      this.form.barcode = text
    }
  },
  created: function () {
    window.addEventListener('keydown', this.keyDown)
  },

  destroyed: function () {
    window.removeEventListener('keydown', this.keyDown)
  }
}
</script>

<style lang="scss">
</style>
